<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Add Missing Shift</h1>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
      <div class=" card rounded-xl bg-white p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-7/12">
        <div class="" style="height: 75vh;overflow: auto;">
          <div class="mb-4 sm:w-6/12">
              <MultiSelect 
              :inputext="employeeSearchText" 
              :inputId="'empslectinpuid'"
              :lableText="'Employee'"
              :placholderText="`Select Employee`"
              :autoFocus="false"
              :showArrow="true"
              :setReadOnly="false"
              :searchCallApi="false"
              :fieldError="empErr !== ''"
              :showPlus="false"
              :keyName="'fullName'"
              :idKey="'userDetailId'"
              :sectionId="'employeelistsecid'"
              :listId="'employeelistidb'"
              :items="employeeList"
              :selectedValues="selectedEmployee"
              @selectNewForVal="selectEmployee"
              />
              <p class="text-error heading-7" v-if="empErr !== ''">{{empErr }}</p>
            </div>
            <div class="divider my-2"></div>
            <div class="">
              <SheduleVisit :showEmployeeSelection="false" :showJobItemSelection="false" :title="'Shift Time'" :type="'oneVisit'" :visitErr="false" />
            </div>
            <div class="divider my-2"></div>
            <div>
              <p class="mb-2">Break Taken</p>
              <p class="text-error heading-7" v-if="breakErr !== ''">{{breakErr }}</p>
              <div class="p-2 text-center"  v-for="(data, index) in breakList" :key="index">
               <p class="heading-5 text-text2 font-semibold">{{data.breakTitle}}</p>
                <div class="flex justify-center pb-2">
                  <div class="gap-2 pt-1 pb-1 pl-2 flex items-center text-center">
                      <div class="">
                        <button :class="data.totalTakenTime === 0 ? 'border border-gray3 text-black cursor-default' : 'bg-primary  text-white'" class="flex justify-center items-center h-8 w-8 text-center rounded " @click="decreaseHours(data)"  small>
                          <span>-</span>
                        </button>
                      </div>
                      <p class="text-text2 heading-5 w-32">{{data.totalTakenTime | ConvertMinutesInHoursAndMinutes}}</p>
                      <div class="ml-1">
                        <button class="flex justify-center items-center h-8 w-8 text-center rounded bg-primary  text-white"
                          @click="increaseHours(data)" small>+
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="breakList.length !== index + 1" class="divider"></div>
              </div>
            </div>
        </div>
        <div class="flex justify-end ">
          <div class="text-rigth flex gap-2">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveShift()"/>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
   </div>
</template>
<script>
import SheduleVisit from '@/View/job/components/SheduleVisit.vue'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  name: "customer",
  components: {
    SheduleVisit,
    Button,
    MultiSelect,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      breakHour:10,
      empErr: '',
      breakErr: '',
      sendObj: {
        shiftStartTime: '',
        shiftEndTime: '',
        selectedEmployees: [],
        selectedBreaks: []
      },
      breakList: [],
      employeeSearchText: '',
      selectedEmployee: [],
      employeeList: [],
      timeErr: false,
      visitStartDateTime: '',
      visitEndDateTime: '',
      totalShiftTime: 0,
      totalBreakTime: 0,
      isError: true,
      fromToTimeError: false,
    }
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  watch: {
  },
  mounted() {
    this.getEmployeeList()
    this.getBreakLists()
    this.$root.$on('finalvisitlistHandler', (response) => {
      if (response[0].Error) {
        this.fromToTimeError = true
      } else {
        this.fromToTimeError = false
      }
      this.breakErr = ''
      this.sendObj.shiftStartTime = response[0].visitStartDateTime
      this.sendObj.shiftEndTime = response[0].visitEndDateTime
      let x = new Date(this.sendObj.shiftStartTime).getTime()
      let y = new Date(this.sendObj.shiftEndTime).getTime()
      this.totalShiftTime = Math.round(((y - x) / 1000) / 60)
    })
  },
  beforeDestroy() {
    this.$root.$off("finalvisitlistHandler");
  },
  methods: {
    increaseHours (data) {
      data.totalTakenTime += 10
      this.countBreaktotal()
    },
    decreaseHours (data) {
      if (data.totalTakenTime > 0) {
        data.totalTakenTime -= 10
      }
      this.countBreaktotal()
    },
    countBreaktotal () {
      this.breakErr = ''
      this.totalBreakTime = 0
      for (let index = 0; index < this.breakList.length; index++) {
        this.totalBreakTime += this.breakList[index].totalTakenTime
      }
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
            temp[index].employeeFirstName = temp[index].firstName 
            temp[index].employeeLastName = temp[index].lastName
            temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
          }
          this.employeeList = temp
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    selectEmployee (emplist) {
      this.empErr = ''
      this.selectedEmployee = emplist
    },
    saveShift () {
      this.countBreaktotal()
      if (this.selectedEmployee.length === 0) {
        this.empErr = 'Atleast one employee is required'
        this.isError = true
      } else {
        this.isError = false
      }
      if (!this.fromToTimeError) {
        if (this.totalBreakTime > this.totalShiftTime) {
          this.breakErr = 'Break time cannot be greater then work time'
          this.isError = true
        } else {
          this.isError = false
        }
      }
      if (!this.isError && this.empErr === '' && this.breakErr === '' && !this.fromToTimeError) {
        let tempUser = []
        let tempBreak = []
        for (let index = 0; index <  this.selectedEmployee.length; index++) {
          let Obj1 = {
            employeeId: this.selectedEmployee[index].userDetailId,
            employeeFirstName: this.selectedEmployee[index].employeeFirstName,
            employeeLastName: this.selectedEmployee[index].employeeLastName,
          }
          tempUser.push(Obj1)
        }
        this.sendObj.selectedEmployees = tempUser
        
        for (let index = 0; index < this.breakList.length; index++) {
            let Obj2 = {
              breakTitle: this.breakList[index].breakTitle,
              breakId: this.breakList[index].breakId,
              totalTakenTime: this.breakList[index].totalTakenTime,
              isTaken: false
            }
            tempBreak.push(Obj2)
        }
        this.sendObj.selectedBreaks = tempBreak
        MyJobApp.addNewShift(
          this.sendObj,
          response => {
            this.$router.push({name: 'shift'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
    getBreakLists(){
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      MyJobApp.getBreakList(
        response => {
          let breakList = response.Data
          breakList.forEach(a => {
            let obj = {
              breakTitle: a.breakTitle,
              breakId:  a.breakId,
              totalTakenTime: 0,
              isTaken: false,
            }
            this.breakList.push(obj)
          })
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        })
      
    }
  },
};
</script>
<style scoped>

</style>